import { Layout, LayoutBody, LayoutHeader } from '@retail/backoffice-ui';
import { Space, Table } from 'antd';
import { getMonth } from 'date-fns';
import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { withPermissions } from '~/components/PermissionChecker';
import { CohortType } from '~/constants/CohortType';
import { PeriodType } from '~/constants/PeriodType';
import { DOMAIN } from '~/constants/domain';
import { PERMISSION } from '~/constants/permission';
import { useUser } from '~/hooks/useUser';

import { SearchForm } from './SearchForm';
import { useConfiguredColumns } from './hooks/useConfiguredColumns';
import { useCountryCodeOptions } from './hooks/useCountryCodeOptions';
import { useDataSource } from './hooks/useDataSource';
import cn from './styles.less';
import { SearchFormModel } from './types';

export const SalesScoreboard = withPermissions(
  PERMISSION.QUERY_SEARCH_SALES_SCOREBOARD_RESULTS,
  DOMAIN.SALES_SCOREBOARD_MANAGEMENT
)(() => {
  const { t } = useTranslation();
  const countryCodeOptions = useCountryCodeOptions();
  const { dataSource, loading, onSubmit } = useDataSource();
  const { country } = useUser();
  const form = useForm<SearchFormModel>({
    defaultValues: {
      countryCode: country,
      cohort: CohortType.VERIFIED,
      periodType: PeriodType.MONTHLY,
      period: getMonth(new Date()) + 1,
      year: new Date().getFullYear()
    },
    mode: 'all'
  });
  const {
    control,
    formState: { isValid },
    handleSubmit
  } = form;
  const [countryCode, cohort, periodType, period, year] = useWatch({
    control,
    name: ['countryCode', 'cohort', 'periodType', 'period', 'year']
  });

  const { columns, loading: columnsLoading } = useConfiguredColumns({
    countryCode,
    countryCodeOptions
  });

  useEffect(() => {
    /**
     * @description can be called twice because we change {@link period} when {@link periodType} is changed
     */
    const timer = setTimeout(() =>
      onSubmit({
        countryCode,
        cohort,
        periodType,
        period,
        year
      } as SearchFormModel)
    );

    return () => clearTimeout(timer);
  }, [countryCode, cohort, periodType, period, year, onSubmit]);

  return (
    <Layout>
      <LayoutHeader title={t('bo.salesScoreboard.title')} />
      <LayoutBody>
        <Space direction="vertical" size="large" className={cn.fullWidth}>
          <FormProvider {...form}>
            <SearchForm
              loading={loading}
              control={control}
              countryCodeOptions={countryCodeOptions}
              onSubmit={handleSubmit(onSubmit)}
              disabled={!isValid}
            />
          </FormProvider>
          <Table
            rowKey={({ place, salesAgent, countryCode }) =>
              [place, salesAgent?.id, countryCode].filter(Boolean).join('-')
            }
            pagination={false}
            dataSource={columns.length ? dataSource : []}
            columns={columns}
            loading={loading || columnsLoading}
            scroll={{ x: 0 }}
          />
        </Space>
      </LayoutBody>
    </Layout>
  );
});
